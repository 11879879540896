'use client';

import { useTransition } from 'react';
import { useLocale } from 'next-intl';
import { useRouter, usePathname } from 'next/navigation';
import clsx from 'clsx';
import 'flag-icons/css/flag-icons.min.css';
import { Button } from './Button';
type Props = {
  className?: string;
};
export const LanguageSwitcher = ({
  className
}: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const [, startTransition] = useTransition();
  const locale = useLocale();

  // const languages = {
  //   en: { flag: 'gb', label: 'English' },
  //   pl: { flag: 'pl', label: 'Polski' },
  // };

  const otherLocale = locale === 'pl' ? 'en' : 'pl';
  // const { flag } = languages[otherLocale];

  const handleClick = () => {
    startTransition(() => {
      const newPath = pathname.split('/');
      newPath[1] = otherLocale;
      router.push(newPath.join('/'));
    });
  };
  return <div className={clsx('flex', className)} data-sentry-component="LanguageSwitcher" data-sentry-source-file="LanguageSwitcher.tsx">
      <Button variant="outline" color="slate" onClick={handleClick} className={clsx('cursor-pointer py-2.5 ring-transparent hover:ring-transparent opacity-80 pr-8 font-semibold')} data-sentry-element="Button" data-sentry-source-file="LanguageSwitcher.tsx">
        {otherLocale.toLocaleUpperCase()}
      </Button>
    </div>;
};