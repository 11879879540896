'use client';

import { useTranslations } from 'next-intl';

// import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
// import logoWebAmigos from '@/images/logos/web_amigos_logo_blue_oneliner.svg';
// import logoOpenRage from '@/images/logos/openrate-logo.svg';
// import logoSalesyy from '@/images/logos/Logo_SalesYY.svg';
// import logoNorthStar from '@/images/logos/nslogo2-1.svg';
import { DynamicTypewriter } from './DynamicTypewriter';

// const companies = [
//   [
//     { name: 'Salesyy', logo: logoSalesyy },
//     { name: 'NorthStar', logo: logoNorthStar },
//     { name: 'OpenRate', logo: logoOpenRage },
//   ],
//   [{ name: 'WebAmigos', logo: logoWebAmigos }],
// ];

export function Hero() {
  const t = useTranslations('Hero');
  const texts = [t('marketing-slogan1'), t('marketing-slogan2'), t('marketing-slogan3'), t('marketing-slogan4')
  // t('marketing-slogan5'),
  // t('marketing-slogan6'),
  ];
  return <div className="relative isolate px-6 pt-14 lg:px-8" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
        <div style={{
        clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
      }} className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-indigo-400 to-[#ffb1d2] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" />
      </div>
      <Container className="pb-36 pt-20 text-center lg:pt-36" data-sentry-element="Container" data-sentry-source-file="Hero.tsx">
        <h1 className="mx-auto max-w-3xl font-display text-xl text-slate-900 sm:text-5xl">
          {t('marketing-title')}{' '}
        </h1>
        <span className="relative whitespace-nowrap text-indigo-600"></span>
        <h2 className="mx-auto max-w-3xl font-display text-xl text-slate-900 sm:text-6xl my-8">
          <span className="relative whitespace-nowrap text-indigo-600 block font-medium tracking-tight">
            <DynamicTypewriter texts={texts} data-sentry-element="DynamicTypewriter" data-sentry-source-file="Hero.tsx" />
          </span>
        </h2>
        <p className="mx-auto mt-6 max-w-2xl text-lg text-slate-700">
          {t('marketing-subtitle1')}
        </p>
        <p className="mx-auto max-w-2xl text-lg text-slate-700 mt-4">
          {t('marketing-subtitle2')}
        </p>
        <div className="mt-12 flex justify-center gap-x-6">
          {/* <Button
            href="https://app.zencal.io/u/patryk-omiotek/spotkanie-30-min"
            target="_blank"
            className="text-xl px-6 py-2 bg-red-600 hover:bg-red-500 hover:text-slate-50"
           >
            {t('hero-button')}
           </Button> */}
        </div>
        <div className="mt-4 max-w-xl lg:mt-0 mx-auto w-full">
          <div className="mt-4 flex justify-center items-center gap-x-6">
            <a href="#get-started-today" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              {t('get-started')}
            </a>
            <a href="#assistant" className="text-base font-semibold text-gray-900">
              {t('learn-more')} <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        {/* <div className="mt-26 lg:mt-44">
         <p className="font-display text-base text-slate-900">
          {t('they-trusted-us')}
         </p>
         <ul
          role="list"
          className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
         >
          {companies.map((group, groupIndex) => (
            <li key={groupIndex}>
              <ul
                role="list"
                className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
              >
                {group.map((company) => (
                  <li key={company.name} className="flex">
                    <Image src={company.logo} alt={company.name} unoptimized />
                  </li>
                ))}
              </ul>
            </li>
          ))}
         </ul>
         </div> */}
      </Container>
    </div>;
}